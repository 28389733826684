import React, { useEffect, useRef, useState } from "react"
import AccountLayout from "../../../components/accountLayout"
import HasAccountManager from "../../../components/hasAccountManager"

const HasAccountManagerPage = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const formInputRef = useRef<{ formLevel: number; hasAccountManager?: string }>()

  useEffect(() => {
    formInputRef.current = { ...formInputRef.current, formLevel: 2 }
  }, [])

  return (
    <AccountLayout
      title="Add an account manager"
      to={
        formInputRef.current?.hasAccountManager === "yes"
          ? "/account-details/private-partnership-host/account-manager"
          : "/account-details/status"
      }
      isDisabled={isDisabled}
    >
      <HasAccountManager formInputRef={formInputRef} setIsDisabled={setIsDisabled} />
    </AccountLayout>
  )
}

export default HasAccountManagerPage
